

.container-bananinha{
    height: 100vh;
    width: 100vw;
    background-image: url("/src/assets/bananinha/background-bananinha.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size:cover;
    display: flex;
    justify-content: center;
    
}
.content-group-wrapper-bananinha{
    display: flex;
    flex-direction: column;
    height: fit-content;
    width: fit-content;
    
}
.content-group-container-bananinha{
    display: flex;
    flex-direction: row;
    height: fit-content;
    width: fit-content;
    transform: translate(-200px,-100px);

}
.content-group2-container-bananinha{
    display: flex;
    flex-direction: row;
    height: fit-content;
    width: fit-content;
    
}

.content-group3-container-bananinha{
    display: flex;
    flex-direction: column;
    width: fit-content;
    justify-content: center;
    align-items: center;
}
.title-group-container-bananinha{
    display: flex;
    flex-direction: column;
}
.title-container-bananinha{
    align-items: center;
    justify-content: center;
}
.title-bananinha{
    font-size: 8rem;
    font-family: "HeyAugust";
    color: #003611;
    margin-bottom:0;
}
.title2-bananinha{
    font-size: 6rem;
    font-family: "HeyAugust";
    color: #003611;
    margin-top:0;
    margin-bottom:0;
    margin-left: 20px;
}
.title-container2-bananinha{
    width:600px;
    align-items: center;
    justify-content: center;
}
.title3-bananinha{
    font-size: 5rem;
    font-family: "HeyAugust";
    color: white;
    margin-top:0;
    margin-left: 30px;
}
.logo-img-container-bananinha{
    display: flex;
    align-items: center;
    transform: translate(-130px, 70px);
}

.logo-img-bananinha{
    width:250px; 
    height:185px; 
}

.about-bananinha{
    display: flex;
    width: 360px;
    height: 420px;
    border-radius: 50px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: 0;
    border-color: #003611;
    border-style: solid;
    background-color: rgba(255,255,255,0.9);
    bottom: 0;
    transform: translateX(-200px);
    position: absolute;
    flex-direction: column;
    align-items: center;

}
.about-title-bananinha{
    font-size: 2.5rem;
    color: #003611;
    font-family: "Belgates";
    margin-top:0;
    margin-bottom: 0;

}
.about-text-container-bananinha{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.about-text-bananinha{
    font-size: 2rem;
    line-height: 50px;
    color: #3e3f3a;
    font-family: "Belgates";
    margin-top: 0;
    margin-bottom: 0;


}

.produto-nutricional-img-container-bananinha{
    display: flex;
    position: absolute;
    transform: translate(950px, -180px);
}
.produto-nutricional-img-bananinha{
    width:600px; 
    height:898px; 
}
@font-face {
    font-family: "HeyAugust";
    src: url("../../../fonts/Hey August/Hey August.ttf");
  }
  @font-face {
    font-family: "Belgates";
    src: url("../../../fonts/Belgates/Belgates Script.ttf");
  }

@media (max-width: 1620px){
    .content-group-container-bananinha{
        transform: translate(-166.666px,-83.333px);
    
    }
    .title-bananinha{
        font-size: 6.66rem;
    }
    .title2-bananinha{
        font-size: 5rem;
        margin-left: 16.666px;
    }
    .title-container2-bananinha{
        width:500px;
    }
    .title3-bananinha{
        font-size: 4.166rem;
        margin-left: 25px;
    }
    .logo-img-container-bananinha{
        transform: translate(-108.33px, 58.333px);
    }
    .logo-img-bananinha{
        width:208.33px; 
        height:154.166px; 
    }
    .about-bananinha{
        width: 300px;
        height: 350px;
        border-radius: 50px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom: 0;
        bottom: 0;
        transform: translateX(-166.66px);
    
    }
    .about-title-bananinha{
        font-size: 2.083rem;
    
    }
    .about-text-bananinha{
        font-size: 1.666rem;
        line-height: 41.66px;
    
    
    }
    .produto-nutricional-img-container-bananinha{
        transform: translate(791.66px, -150px);
    }
    .produto-nutricional-img-bananinha{
        width:500px; 
        height:748.333px; 
    }

}

@media (max-width: 1386px){

    .content-group-container-bananinha{
        transform: translate(-138.88px,-69.444px);
    
    }
    .title-bananinha{
        font-size: 5.55rem;
    }
    .title2-bananinha{
        font-size: 4.166rem;
        margin-left: 13.88px;
    }
    .title-container2-bananinha{
        width:416.666px;
    }
    .title3-bananinha{
        font-size: 3.4716rem;
        margin-left: 20.833px;
    }
    .logo-img-container-bananinha{
        transform: translate(-90.275px, 48.611px);
    }
    .logo-img-bananinha{
        width:173.61px; 
        height:128.4716px; 
    }
    .about-bananinha{
        width: 250px;
        height: 291.66px;
        border-radius: 50px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom: 0;
        bottom: 0;
        transform: translateX(-138.883px);
    
    }
    .about-title-bananinha{
        font-size: 1.736rem;
    
    }
    .about-text-bananinha{
        font-size: 1.388rem;
        line-height: 34.716px;
    
    
    }
    .produto-nutricional-img-container-bananinha{
        transform: translate(659.7166px, -125px);
    }
    .produto-nutricional-img-bananinha{
        width:416.666px; 
        height:623.611px; 
    }


}
@media (max-width: 960px){
    .content-group-container-bananinha{
        transform: translate(-115.733px,-57.87px);
    
    }
    .title-bananinha{
        font-size: 4.625rem;
    }
    .title2-bananinha{
        font-size: 3.4716rem;
        margin-left: 11.566px;
    }
    .title-container2-bananinha{
        width:347.221px;
    }
    .title3-bananinha{
        font-size: 2.893rem;
        margin-left: 17.361px;
    }
    .logo-img-container-bananinha{
        transform: translate(-75.229px, 40.51px);
    }
    .logo-img-bananinha{
        width:144.675px; 
        height:107.0596px; 
    }
    .about-bananinha{
        width: 208.33px;
        height: 243.05px;
        border-radius: 50px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom: 0;
        bottom: 0;
        transform: translateX(-115.736px);
    
    }
    .about-title-bananinha{
        font-size: 1.4466rem;
    
    }
    .about-text-bananinha{
        font-size: 1.1566rem;
        line-height: 28.93px;
    
    
    }
    .produto-nutricional-img-container-bananinha{
        transform: translate(549.7638px, -104.166px);
    }
    .produto-nutricional-img-bananinha{
        width:347.2216px; 
        height:519.676px; 
    }

}

@media (max-width: 820px){
    .content-group-container-bananinha{
        transform: translate(-96.444px,-48.225px);
    
    }
    .title-bananinha{
        font-size: 3.8541rem;
    }
    .title2-bananinha{
        font-size: 2.893rem;
        margin-left: 9.638px;
    }
    .title-container2-bananinha{
        width:289.351px;
    }
    .title3-bananinha{
        font-size: 2.411rem;
        margin-left: 14.4675px;
    }
    .logo-img-container-bananinha{
        transform: translate(-62.691px, 33.758px);
    }
    .logo-img-bananinha{
        width:120.5625px; 
        height:89.2163px; 
    }
    .about-bananinha{
        width: 173.61px;
        height: 202.54px;
        border-radius: 50px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom: 0;
        bottom: 0;
        transform: translateX(-96.446px);
    
    }
    .about-title-bananinha{
        font-size: 1.2055rem;
    
    }
    .about-text-bananinha{
        font-size: 0.964rem;
        line-height: 24.11px;
    
    
    }
    .produto-nutricional-img-container-bananinha{
        transform: translate(458.1365px, -86.805px);
    }
    .produto-nutricional-img-bananinha{
        width:289.35125px; 
        height:433.0633px; 
    }

}