.container-contra-capa-mobile{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
    align-items: center;
    width: 100vw;
}
.img-contra-capa-mobile{
    width:241.5px; 
    height:178.5px; 
}
.line-container-contra-capa-mobile{
    margin-top: 20px;
    border-bottom:4px solid black;
    height: 4px;
    width: 60vw;
    display: flex;
    align-items: center;
    justify-content: center;

}
.text-bold-container-contra-capa-mobile{
    display: flex;
}
.text-bold-contra-capa-mobile{
    font-size: 0.8rem;
    font-family: "Square721-Bold";
}

.text-container-contra-capa-mobile{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.text-contra-capa-mobile{
    font-size: 0.6rem;
    font-family: "Square721";
    line-height: 2px;
}

@font-face {
    font-family: "Square721";
    src: url("../../../fonts/Square721/square721-cn-bt-roman.ttf");
  }

@font-face {
    font-family: "Square721-Bold";
    src: url("../../../fonts/Square721/Square-721-Bold-Condensed-BT_42056.ttf");
}

