.container-cachaca-vermelha-mobile{ 
    height: 100vh;
    width: 100vw;
    background-repeat: no-repeat;
    background-position: center;
    background-size:cover;
    display: flex;
    justify-content: center;
    background-image: url("/src/assets/cachaca/background.jpg");
    
}
.content-group-wrapper-cachaca-vermelha-mobile{
    display: flex;
    flex-direction: column;
    height: fit-content;
    width: fit-content;
    
}
.content-group-container-cachaca-vermelha-mobile{
    display: flex;
    flex-direction: row;
    height: fit-content;
    width: fit-content;
    transform: translate(-115.275px,-57.866px);

}
.content-group2-container-cachaca-vermelha-mobile{
    display: flex;
    flex-direction: row;
    height: fit-content;
    width: fit-content;
    
}

.content-group3-container-cachaca-vermelha-mobile{
    display: flex;
    flex-direction: column;
    width: fit-content;
    justify-content: center;
    align-items: center;
}
.title-group-container-cachaca-vermelha-mobile{
    display: flex;
    flex-direction: column;
}
.title-container-cachaca-vermelha-mobile{
    align-items: center;
    justify-content: center;
    /* transform: translate(11.56px, 280px); */
    
    
}
.title-cachaca-vermelha-mobile{
    font-size: 3.1825rem;
    font-family: "HeyAugust";
    color: #FFFFFF;
    margin-bottom:0;
    text-align: center;
    text-shadow: 2px 2px #003611;
    position: absolute;
    bottom:42vh;
    
}
.logo-img-container-cachaca-vermelha-mobile{
    display: flex;
    align-items: center;
    transform: translate(35vw, 34vh);
}

.logo-img-cachaca-vermelha-mobile{
    width:100.46px;  
    height:74.715px; 
}

.about-cachaca-vermelha-mobile{
    display: flex;
    width: 208.333px;
    height: fit-content;
    border-radius: 50px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: 0;
    border-color: #003611;
    border-style: solid;
    background-color: rgba(255,255,255,0.9);
    bottom: 0;
    transform: translateX(-115.736px);
    position: absolute;
    flex-direction: column;
    align-items: center;
    padding-bottom: 28.93px;

}
.about-title-cointainer-cachaca-vermelha-mobile{
    display: flex;
    width: fit-content;
    height: fit-content;
    background-color: #BE1622;
    margin-top:11.56px;
    padding: 2.89px;
}
.about-title-cachaca-vermelha-mobile{
    font-size: 0.578rem;
    color: #FFFFFF;
    font-family: "HelveticaLTBold";
    margin-top:0;
    margin-bottom: 0;

}
.about-text-container-cachaca-vermelha-mobile{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 11.566px;
}
.about-text-cachaca-vermelha-mobile{
    font-size: 0.458rem;
    color: #1D1D1B;
    font-family: "HelveticaLTBold";
    margin-top: 0;
}
.about-text2-cachaca-vermelha-mobile{
    font-size: 0.458rem;
    color: #1D1D1B;
    font-family: "HelveticaLT";
    margin-top: 0;

}
.about-text3-cachaca-vermelha-mobile{
    font-size: 0.458rem;
    color: #1D1D1B;
    font-family: "HelveticaLTBold";
    margin-top: 0;
    margin-bottom: 0;
}
.line-container-cachaca-vermelha-mobile{
    border-bottom:2px solid #003611;
    height:2px;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;

}

.produto-nutricional-img-container-cachaca-vermelha-mobile{
    display: flex;
    position: absolute;
    /* transform: translate(289.346px, 210px); */
    bottom: -5vh;
    right: -5vw;
}
.produto-nutricional-img-cachaca-vermelha-mobile{
     width:147px; 
    height:510px; 
}

@font-face {
    font-family: "HeyAugust";
    src: url("../../../fonts/Hey August/Hey August.ttf");
  }
  @font-face {
    font-family: "Belgates";
    src: url("../../../fonts/Belgates/Belgates Script.ttf");
  }
  @font-face {
    font-family: "HelveticaLT";
    src: url("../../../fonts/HelveticaLTCondensed/helvetica-lt-condensed-medium.ttf");
  }
  @font-face {
    font-family: "HelveticaLTBold";
    src: url("../../../fonts/HelveticaLTCondensed/helvetica-lt-condensed-bold.ttf");
  }
  @media (min-height: 620px){
    .container-cachaca-vermelha-mobile{ 
        height: 100vh;
        width: 100vw;
        
    }
    .title-cachaca-vermelha-mobile{
        font-size: 3.1825rem;
        font-family: "HeyAugust";
        color: #FFFFFF;
        margin-bottom:0;
        text-align: center;
        text-shadow: 2px 2px #003611;
        position: absolute;
        bottom:35vh;
        
    }
    .logo-img-container-cachaca-vermelha-mobile{
        transform: translate(35vw, 45vh);
    }
    
    .logo-img-cachaca-vermelha-mobile{
        width:100.46px;  
        height:74.715px; 
    }
    
    .about-cachaca-vermelha-mobile{
        width: 208.333px;
        border-radius: 50px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom: 0;
        background-color: rgba(255,255,255,0.9);
        bottom: 0;
        transform: translateX(-115.736px);
        padding-bottom: 28.93px;
    
    }
    .about-title-cointainer-cachaca-vermelha-mobile{
        margin-top:11.56px;
        padding: 2.89px;
    }
    .about-title-cachaca-vermelha-mobile{
        font-size: 0.578rem;
        margin-top:0;
        margin-bottom: 0;
    
    }
    .about-text-container-cachaca-vermelha-mobile{
        margin-top: 11.566px;
    }
    .about-text-cachaca-vermelha-mobile{
        font-size: 0.458rem;
        margin-top: 0;
    }
    .about-text2-cachaca-vermelha-mobile{
        font-size: 0.458rem;
        margin-top: 0;
    
    }
    .about-text3-cachaca-vermelha-mobile{
        font-size: 0.458rem;
        margin-top: 0;
        margin-bottom: 0;
    }
    .line-container-cachaca-vermelha-mobile{
        border-bottom:2px solid #003611;
        height:2px;
        width: 90%;
        display: flex;
        align-items: center;
        justify-content: center;
    
    }
    
    .produto-nutricional-img-container-cachaca-vermelha-mobile{
        bottom: -5vh;
        right: 0;
    }
    .produto-nutricional-img-cachaca-vermelha-mobile{
        width:147px; 
        height:510px; 
    }
    
  
}

  @media (min-height: 720px){
    .container-cachaca-vermelha-mobile{ 
        height: 100vh;
        width: 100vw;
        
    }
    .content-group-container-cachaca-vermelha-mobile{
        transform: translate(-115.275px,-57.866px);
    
    }
    .title-cachaca-vermelha-mobile{
        font-size: 3.1825rem;
        margin-bottom:0;
        text-align: center;
        text-shadow: 2px 2px #003611;
        position: absolute;
        bottom:32vh;
        
    }
    .logo-img-container-cachaca-vermelha-mobile{
        transform: translate(38vw, 48vh);
    }
    
    .logo-img-cachaca-vermelha-mobile{
        width:100.46px;  
        height:74.715px; 
    }
    
    .about-cachaca-vermelha-mobile{

        width: 208.333px;
        border-radius: 50px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom: 0;
        bottom: 0;
        transform: translateX(-115.736px);
        padding-bottom: 28.93px;
    
    }
    .about-title-cointainer-cachaca-vermelha-mobile{
        margin-top:11.56px;
        padding: 2.89px;
    }
    .about-title-cachaca-vermelha-mobile{
        font-size: 0.578rem;
        margin-top:0;
        margin-bottom: 0;
    
    }
    .about-text-container-cachaca-vermelha-mobile{
        margin-top: 11.566px;
    }
    .about-text-cachaca-vermelha-mobile{
        font-size: 0.458rem;
        margin-top: 0;
    }
    .about-text2-cachaca-vermelha-mobile{
        font-size: 0.458rem;
        margin-top: 0;
    
    }
    .about-text3-cachaca-vermelha-mobile{
        font-size: 0.458rem;
        margin-top: 0;
        margin-bottom: 0;
    }
    .line-container-cachaca-vermelha-mobile{
        border-bottom:2px solid #003611;
        height:2px;
        width: 90%;
    
    }
    
    .produto-nutricional-img-container-cachaca-vermelha-mobile{
        bottom: -5vh;
        right: 0;
    }
    .produto-nutricional-img-cachaca-vermelha-mobile{
        width:147px; 
        height:510px;  
    }
  
}


