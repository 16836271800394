.container-vinho-mobile{
    display:flex;
    height: 100vh;
    width: 100vw;
}
.left-top-vinho-mobile{
    left:0;
    top:0;
    position: absolute;
    width:160px;
    height:160px;
}
.right-top-vinho-mobile{
    right:0;
    top:0;
    position: absolute;
    width:160px; 
    height:160px;
}

.left-bottom-vinho-mobile{
    left:0;
    top:0;
    position: absolute;
    margin-top: 30vh;
    width:160px; 
    height:160px;
}
.right-bottom-vinho-mobile{
    right:0;
    bottom:0;
    position: absolute;
    width:160px; 
    height:160px;
}
.topbar-vinho-mobile{
    width: 100vw;
    height: 30vh;
    top:0;
    background-color: #822330;
    display: flex;
    align-items: center;
    justify-content: center;
}
.title-img-vinho-mobile{
    width:140px; 
    height:122px; 
}
.content-group-vinho-mobile{
    height: 100vh;
    justify-content: flex-start;
    display: flex;
    flex-direction: column;
}

.content-vinho-mobile{
    height:70vh;
    display:flex;
    align-items: center;
    justify-content: center;
}
.produto-vinho-mobile{
    left:50vw;
    margin-bottom: 38vw;
    width:352px; 
    height:381.04px; 
}

.logo-vinho-mobile{
    left:0;
    bottom:0;
    position: absolute;
    margin-left: 20px;
    margin-bottom: 28px;
    width:80px;
    height:59.2px; 

}
.logo2-vinho-mobile{
    right:0;
    bottom:0;
    position: absolute;
    margin-right: 20px;
    margin-bottom: 20px;
    width:80px; 
    height:75.6px;

}

