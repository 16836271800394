.container-vinho{
    display:flex;
    height: 100vh;
    width: 100vw;
}
.left-top-vinho{
    left:0;
    top:0;
    position: absolute;
    width:400px;
    height:400px;
}
.right-top-vinho{
    right:0;
    top:0;
    position: absolute;
    width:400px; 
    height:400px;
}

.left-bottom-vinho{
    left:0;
    bottom:0;
    position: absolute;
    margin-bottom: 280px;
    width:400px; 
    height:400px;
}
.right-bottom-vinho{
    right:0;
    bottom:0;
    position: absolute;
    width:400px; 
    height:400px;
}
.topbar-vinho{
    width: 100vw;
    height: 30vh;
    top:0;
    background-color: #822330;
    display: flex;
    align-items: center;
    justify-content: center;
}
.title-img-vinho{
    width:350px; 
    height:305px; 
}
.content-group-vinho{
    height: 100vh;
    justify-content: flex-start;
    display: flex;
    flex-direction: column;
}

.content-vinho{
    height:70vh;
    display:flex;
    align-items: center;
    justify-content: center;
}
.produto-vinho{
    margin-left: 300px;
    width:800px; 
    height:866px; 
}

.logo-vinho{
    left:0;
    bottom:0;
    position: absolute;
    margin-left: 50px;
    margin-bottom: 70px;
    width:200px;
    height:148px; 

}
.logo2-vinho{
    right:0;
    bottom:0;
    position: absolute;
    margin-right: 50px;
    margin-bottom: 50px;
    width:200px; 
    height:189px;

}

@media (max-width: 1620px){
    .left-top-vinho{
        width:333.333px;
        height:333.333px;
    }
    .right-top-vinho{
        width:333.333px;
        height:333.333px;
    }
    .left-bottom-vinho{
        margin-bottom: 233.333px;
        width:333.333px;
        height:333.333px;
    }
    .right-bottom-vinho{
        width:333.333px;
        height:333.333px;
    }
    .title-img-vinho{
        width:291.66px; 
        height:254.166px; 
    }
    .produto-vinho{
        margin-left: 250px;
        width:666.666px; 
        height:721.66px; 
    }
    .logo-vinho{
        margin-left: 41.666px;
        margin-bottom: 58.33px;
        width:166.666px;
        height:123.333px; 
    
    }
    .logo2-vinho{
        margin-right: 41.666px;
        margin-bottom: 41.666px;
        width:166.666px;
        height:157.5px;
    
    }


}
@media (max-width: 1386px){

    .left-top-vinho{
        width:277.777px;
        height:277.777px;
    }
    .right-top-vinho{
        width:277.777px;
        height:277.777px;
    }
    .left-bottom-vinho{
        margin-bottom: 194.444px;
        width:277.777px;
        height:277.777px;
    }
    .right-bottom-vinho{
        width:277.777px;
        height:277.777px;
    }
    .title-img-vinho{
        width:243.05px; 
        height:211.805px; 
    }
    .produto-vinho{
        margin-left: 208.333px;
        width:555.555px; 
        height:601.383px; 
    }
    .logo-vinho{
        margin-left: 34.7216px;
        margin-bottom: 48.61px;
        width:138.888px;
        height:102.7775px; 
    
    }
    .logo2-vinho{
        margin-right: 34.7216px;
        margin-bottom: 34.7216px;
        width:138.888px;
        height:131.25px;
    
    }



}

@media (max-width: 960px){
   
    .left-top-vinho{
        width:231.481px;
        height:231.481px;
    }
    .right-top-vinho{
        width:231.481px;
        height:231.481px;
    }
    .left-bottom-vinho{
        margin-bottom: 162.036px;
        width:231.481px;
        height:231.481px;
    }
    .right-bottom-vinho{
        width:231.481px;
        height:231.481px;
    }
    .title-img-vinho{
        width:202.5416px; 
        height:176.50px; 
    }
    .produto-vinho{
        margin-left: 173.611px;
        width:462.9625px; 
        height:501.1525px; 
    }
    .logo-vinho{
        margin-left: 28.9346px;
        margin-bottom: 40.51px;
        width:115.74px;
        height:85.6479px; 
    
    }
    .logo2-vinho{
        margin-right: 28.9346px;
        margin-bottom: 28.9346px;
        width:115.74px;
        height:109.375px;
    }

}
@media (max-width: 820px){

    .left-top-vinho{
        width:192.9px;
        height:192.9px;
    }
    .right-top-vinho{
        width:192.9px;
        height:192.9px;
    }
    .left-bottom-vinho{
        margin-bottom: 135.03px;
        width:192.9px;
        height:192.9px;
    }
    .right-bottom-vinho{
        width:192.9px;
        height:192.9px;
    }
    .title-img-vinho{
        width:168.784px; 
        height:147.083px; 
    }
    .produto-vinho{
        margin-left: 144.6758px;
        width:385.802px; 
        height:417.627px; 
    }
    .logo-vinho{
        margin-left: 24.112px;
        margin-bottom: 33.758px;
        width:96.45px;
        height:71.37px; 
    
    }
    .logo2-vinho{
        margin-right: 24.112px;
        margin-bottom: 24.112px;
        width:96.45px;
        height:91.146px;
    }


}