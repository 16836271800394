.container-doce-de-leite{
    height: 100vh;
    width: 100vw;
    background-image: url("/src/assets/doce-de-leite/background-doce-de-leite.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size:cover;
    display: flex;
    justify-content: center;
}
.title-content-doce-de-leite{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.title-logo-doce-de-leite{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}
.title-doce-de-leite{
    font-family:"HeyAugust";
    color: #FFFFFF;
    text-align: right;
    margin-bottom: 0;
    font-size: 5rem;
}
.logo-doce-de-leite{
    width:250px; 
    height:185px; 
    transform: translateX(50px);
}
.subtitle-doce-de-leite{
    font-family:"Belgates";
    color: #1D1D1B;
    text-align: right;
    margin-top: 0;
    margin-left: 500px;
    font-size: 2rem;
}


.about-doce-de-leite{
    display: flex;
    width: 360px;
    height: fit-content;
    border-radius: 50px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: 0;
    border-color: #003611;
    border-style: solid;
    background-color: rgba(255,255,255,0.9);
    bottom: 0;
    transform: translateX(-200px);
    position: absolute;
    flex-direction: column;
    align-items: center;
    padding-bottom: 50px;

}
.about-title-cointainer-doce-de-leite{
    display: flex;
    width: fit-content;
    height: fit-content;
    margin-top:20px;
    padding: 5px;
}
.about-title-doce-de-leite{
    font-size: 2rem;
    color: #1D1D1B;
    font-family: "FreeHand";
    margin-top:0;
    margin-bottom: 0;

}
.about-text-container-doce-de-leite{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}
.about-text-doce-de-leite{
    font-size: 1.5rem;
    color: #1D1D1B;
    font-family: "FreeHand";
    text-align: center;
    margin-top: 0;
}
.about-text3-doce-de-leite{
    font-size: 0.8rem;
    color: #1D1D1B;
    font-family: "HelveticaLTBold";
    margin-top: 0;
    margin-bottom: 0;
}
.line-container-doce-de-leite{
    border-bottom:2px solid #003611;
    height:2px;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;

}
.produto-doce-de-leite{
    position: absolute;
    bottom: 0;
    right: 30vw;
    width: 300px;
    height:525px;
}

@font-face {
    font-family: "HeyAugust";
    src: url("../../../fonts/Hey August/Hey August.ttf");
  }

@font-face {
    font-family: "Belgates";
    src: url("../../../fonts/Belgates/Belgates Script.ttf");
  }

@font-face {
    font-family: "FreeHand";
    src: url("../../../fonts/FreeHand/Freehand521BT.ttf");
  }
@media (max-width: 1940px){

    .title-doce-de-leite{
        margin-bottom: 0;
        font-size: 5rem;
    }
    .logo-doce-de-leite{
        width:250px; 
        height:185px; 
        transform: translateX(50px);
    }
    .subtitle-doce-de-leite{
        margin-top: 0;
        margin-left: 500px;
        font-size: 2rem;
    }
    
    
    .about-doce-de-leite{
        display: flex;
        width: 360px;
        height: fit-content;
        border-radius: 50px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom: 0;
        background-color: rgba(255,255,255,0.9);
        bottom: 0;
        transform: translateX(-200px);
        padding-bottom: 50px;
    
    }
    .about-title-cointainer-doce-de-leite{
        margin-top:20px;
        padding: 5px;
    }
    .about-title-doce-de-leite{
        font-size: 2rem;
        margin-top:0;
        margin-bottom: 0;
    
    }
    .about-text-container-doce-de-leite{
        margin-top: 20px;
    }
    .about-text-doce-de-leite{
        font-size: 1.5rem;
        margin-top: 0;
    }
    .about-text3-doce-de-leite{
        font-size: 0.8rem;
        margin-top: 0;
        margin-bottom: 0;
    }
    .line-container-doce-de-leite{
        border-bottom:2px solid #003611;
        height:2px;
        width: 90%;
    
    }
    .produto-doce-de-leite{
        bottom: 0;
        right: 20vw;
        width: 300px;
        height:525px;
    }

}  
@media (max-width: 1620px){
    .title-doce-de-leite{
        margin-bottom: 0;
        font-size: 4.16rem;
    }
    .logo-doce-de-leite{
        width:208.33px; 
        height:154.166px; 
        transform: translateX(41.66px);
    }
    .subtitle-doce-de-leite{
        margin-top: 0;
        margin-left: 416.666px;
        font-size: 1.66rem;
    }
    
    
    .about-doce-de-leite{
        display: flex;
        width: 300px;
        height: fit-content;
        border-radius: 50px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom: 0;
        background-color: rgba(255,255,255,0.9);
        bottom: 0;
        transform: translateX(-166.66px);
        padding-bottom: 41.66px;
    
    }
    .about-title-cointainer-doce-de-leite{
        margin-top:16.66px;
        padding: 4.166px;
    }
    .about-title-doce-de-leite{
        font-size: 1.66rem;
        margin-top:0;
        margin-bottom: 0;
    
    }
    .about-text-container-doce-de-leite{
        margin-top: 16.66px;
    }
    .about-text-doce-de-leite{
        font-size: 1.25rem;
        margin-top: 0;
    }
    .about-text3-doce-de-leite{
        font-size: 0.666rem;
        margin-top: 0;
        margin-bottom: 0;
    }
    .line-container-doce-de-leite{
        border-bottom:2px solid #003611;
        height:2px;
        width: 90%;
    
    }
    .produto-doce-de-leite{
        bottom: 0;
        right: 16.66vw;
        width: 250px;
        height:437.5px;
    }

}
@media (max-width: 1386px){
    .title-doce-de-leite{
        margin-bottom: 0;
        font-size: 3.466rem;
    }
    .logo-doce-de-leite{
        width:173.60px; 
        height:128.47px; 
        transform: translateX(34.71px);
    }
    .subtitle-doce-de-leite{
        margin-top: 0;
        margin-left: 347.216px;
        font-size: 1.3833rem;
    }
    
    
    .about-doce-de-leite{
        display: flex;
        width: 250px;
        height: fit-content;
        border-radius: 50px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom: 0;
        background-color: rgba(255,255,255,0.9);
        bottom: 0;
        transform: translateX(-138.88px);
        padding-bottom: 34.716px;
    
    }
    .about-title-cointainer-doce-de-leite{
        margin-top:13.883px;
        padding: 3.471px;
    }
    .about-title-doce-de-leite{
        font-size: 1.383rem;
        margin-top:0;
        margin-bottom: 0;
    
    }
    .about-text-container-doce-de-leite{
        margin-top: 13.88px;
    }
    .about-text-doce-de-leite{
        font-size: 1.041rem;
        margin-top: 0;
    }
    .about-text3-doce-de-leite{
        font-size: 0.555rem;
        margin-top: 0;
        margin-bottom: 0;
    }
    .line-container-doce-de-leite{
        border-bottom:2px solid #003611;
        height:2px;
        width: 90%;
    
    }
    .produto-doce-de-leite{
        bottom: 0;
        right: 20.88vw;
        width: 208.33px;
        height:364.58px;
    }

}
@media (max-width: 1300px){
    .title-doce-de-leite{
        margin-bottom: 0;
        font-size: 3.466rem;
    }
    .logo-doce-de-leite{
        width:173.60px; 
        height:128.47px; 
        transform: translateX(34.71px);
    }
    .subtitle-doce-de-leite{
        margin-top: 0;
        margin-left: 347.216px;
        font-size: 1.3833rem;
    }
    
    
    .about-doce-de-leite{
        display: flex;
        width: 250px;
        height: fit-content;
        border-radius: 50px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom: 0;
        background-color: rgba(255,255,255,0.9);
        bottom: 0;
        transform: translateX(-138.88px);
        padding-bottom: 34.716px;
    
    }
    .about-title-cointainer-doce-de-leite{
        margin-top:13.883px;
        padding: 3.471px;
    }
    .about-title-doce-de-leite{
        font-size: 1.383rem;
        margin-top:0;
        margin-bottom: 0;
    
    }
    .about-text-container-doce-de-leite{
        margin-top: 13.88px;
    }
    .about-text-doce-de-leite{
        font-size: 1.041rem;
        margin-top: 0;
    }
    .about-text3-doce-de-leite{
        font-size: 0.555rem;
        margin-top: 0;
        margin-bottom: 0;
    }
    .line-container-doce-de-leite{
        border-bottom:2px solid #003611;
        height:2px;
        width: 90%;
    
    }
    .produto-doce-de-leite{
        bottom: 0;
        right: 20.88vw;
        width: 208.33px;
        height:364.58px;
    }


}
@media (max-width: 980px){
    .title-doce-de-leite{
        margin-bottom: 0;
        font-size: 2.88rem;
    }
    .logo-doce-de-leite{
        width:144.66px; 
        height:107.05px; 
        transform: translateX(28.925px);
    }
    .subtitle-doce-de-leite{
        margin-top: 0;
        margin-left: 289.346px;
        font-size: 1.152rem;
    }
    
    
    .about-doce-de-leite{
        display: flex;
        width: 208.33px;
        height: fit-content;
        border-radius: 50px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom: 0;
        background-color: rgba(255,255,255,0.9);
        bottom: 0;
        transform: translateX(-115.733px);
        padding-bottom: 28.93px;
    
    }
    .about-title-cointainer-doce-de-leite{
        margin-top:11.569px;
        padding: 2.8925px;
    }
    .about-title-doce-de-leite{
        font-size: 1.1525rem;
        margin-top:0;
        margin-bottom: 0;
    
    }
    .about-text-container-doce-de-leite{
        margin-top: 11.566px;
    }
    .about-text-doce-de-leite{
        font-size: 0.8675rem;
        margin-top: 0;
    }
    .about-text3-doce-de-leite{
        font-size: 0.4625rem;
        margin-top: 0;
        margin-bottom: 0;
    }
    .line-container-doce-de-leite{
        border-bottom:2px solid #003611;
        height:2px;
        width: 90%;
    
    }
    .produto-doce-de-leite{
        bottom: 0;
        right: 17.4vw;
        width: 173.61px;
        height:303.81px;
    }


}