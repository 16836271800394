.container-capa{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    align-items: center;
    width:100vw;
}
.logo-capa{
    margin-top: 150px;
    width:491px; 
    height:486px;
}

.footer-capa{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    bottom: 0;
    width:100vw;
    height: 20vh;
    color:white;
    background-color: #e30613;

}
 .footer-title-capa{
    font-size: 2rem;
} 

@media (max-width: 1620px){
    .logo-capa{
        margin-top: 125px;
        width:409.166px; 
        height:405px;
    }
    
    .footer-title-capa{
        font-size: 1.66rem;
    } 
    
    


}

@media (max-width: 1386px){
    .logo-capa{
        margin-top: 104.166px;
        width:340.9716px; 
        height:337.5px;
    }
    .footer-title-capa{
        font-size: 1.3833rem;
    } 
    
}

@media (max-width: 980px){
    .logo-capa{
        margin-top: 86.805px;
        width:284.143px; 
        height:281.25px;
    }
    
    .footer-title-capa{
        font-size: 1.15275rem;
    } 

}
@media (max-width: 820px){
    .logo-capa{
        margin-top: 72.3375px;
        width:236.7858px; 
        height:234.375px;
    }
    
    .footer-title-capa{
        font-size: 0.960625rem;
    } 

}
