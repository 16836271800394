.container-cachaca-vermelha{ 
    height: 100vh;
    width: 100vw;
    background-repeat: no-repeat;
    background-position: center;
    background-size:cover;
    display: flex;
    justify-content: center;
    background-image: url("/src/assets/cachaca-vermelha/background.jpg");
    
}
.content-group-wrapper-cachaca-vermelha{
    display: flex;
    flex-direction: column;
    height: fit-content;
    width: fit-content;
    
}
.content-group-container-cachaca-vermelha{
    display: flex;
    flex-direction: row;
    height: fit-content;
    width: fit-content;
    transform: translate(-200px,-100px);

}
.content-group2-container-cachaca-vermelha{
    display: flex;
    flex-direction: row;
    height: fit-content;
    width: fit-content;
    
}

.content-group3-container-cachaca-vermelha{
    display: flex;
    flex-direction: column;
    width: fit-content;
    justify-content: center;
    align-items: center;
}
.title-group-container-cachaca-vermelha{
    display: flex;
    flex-direction: column;
}
.title-container-cachaca-vermelha{
    align-items: center;
    justify-content: center;
    transform: translate(20px, 350px);
    
}
.title-cachaca-vermelha{
    font-size: 5.5rem;
    font-family: "HeyAugust";
    color: #FFFFFF;
    margin-bottom:0;
    text-align: center;
    text-shadow: 2px 2px #003611;
    
}
.logo-img-container-cachaca-vermelha{
    display: flex;
    align-items: center;
    transform: translate(160px, 260px);
}

.logo-img-cachaca-vermelha{
    width:173.61px; 
    height:128.4716px; 
}

.about-cachaca-vermelha{
    display: flex;
    width: 360px;
    height: fit-content;
    border-radius: 50px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: 0;
    border-color: #003611;
    border-style: solid;
    background-color: rgba(255,255,255,0.9);
    bottom: 0;
    transform: translateX(-200px);
    position: absolute;
    flex-direction: column;
    align-items: center;
    padding-bottom: 50px;

}
.about-title-cointainer-cachaca-vermelha{
    display: flex;
    width: fit-content;
    height: fit-content;
    background-color: #BE1622;
    margin-top:20px;
    padding: 5px;
}
.about-title-cachaca-vermelha{
    font-size: 1rem;
    color: #FFFFFF;
    font-family: "HelveticaLTBold";
    margin-top:0;
    margin-bottom: 0;

}
.about-text-container-cachaca-vermelha{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 20px;
}
.about-text-cachaca-vermelha{
    font-size: 0.8rem;
    color: #1D1D1B;
    font-family: "HelveticaLTBold";
    margin-top: 0;
}
.about-text2-cachaca-vermelha{
    font-size: 0.8rem;
    color: #1D1D1B;
    font-family: "HelveticaLT";
    margin-top: 0;

}
.about-text3-cachaca-vermelha{
    font-size: 0.8rem;
    color: #1D1D1B;
    font-family: "HelveticaLTBold";
    margin-top: 0;
    margin-bottom: 0;
}
.line-container-cachaca-vermelha{
    border-bottom:2px solid #003611;
    height:2px;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;

}

.produto-nutricional-img-container-cachaca-vermelha{
    display: flex;
    position: absolute;
    transform: translate(500px, 280px);
}
.produto-nutricional-img-cachaca-vermelha{
    width:248px; 
    height:861px; 
}
/* mudar as dimensões da imagem */
@font-face {
    font-family: "HeyAugust";
    src: url("../../../fonts/Hey August/Hey August.ttf");
  }
  @font-face {
    font-family: "Belgates";
    src: url("../../../fonts/Belgates/Belgates Script.ttf");
  }
  @font-face {
    font-family: "HelveticaLT";
    src: url("../../../fonts/HelveticaLTCondensed/helvetica-lt-condensed-medium.ttf");
  }
  @font-face {
    font-family: "HelveticaLTBold";
    src: url("../../../fonts/HelveticaLTCondensed/helvetica-lt-condensed-bold.ttf");
  }
/* Ver o de 1920 x 1080*/
@media (max-width: 1940px){
    .content-group-container-cachaca-vermelha{
        transform: translate(-200px,-100px);
    
    }
    .title-container-cachaca-vermelha{
        transform: translate(20px, 340px);
        
    }
    .title-cachaca-vermelha{
        font-size: 5.5rem;
        margin-bottom:0;
        
    }
    .logo-img-container-cachaca-vermelha{
        transform: translate(160px, 260px);
    }
    .logo-img-cachaca-vermelha{
        width:173.61px; 
        height:128.4716px; 
    }
    .about-cachaca-vermelha{
        width: 360px;
        border-radius: 50px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom: 0;
        border-style: solid;
        bottom: 0;
        transform: translateX(-200px);
        padding-bottom: 50px;
    
    }
    .about-title-cointainer-cachaca-vermelha{
        margin-top:20px;
        padding: 5px;
    }

    .about-title-cachaca-vermelha{
        font-size: 1rem;
        margin-top:0;
        margin-bottom: 0;
    
    }
    .about-text-container-cachaca-vermelha{
        margin-top: 20px;
    }
    .about-text-cachaca-vermelha{
        font-size: 0.8rem;
        margin-top: 0;
    }
    .about-text2-cachaca-vermelha{
        font-size: 0.8rem;
        margin-top: 0;
    
    }
    .about-text3-cachaca-vermelha{
        font-size: 0.8rem;
        margin-top: 0;
        margin-bottom: 0;
    }
    .line-container-cachaca-vermelha{
        border-bottom:2px solid #003611;
        height:2px;
        width: 90%;
    
    }
    
    .produto-nutricional-img-container-cachaca-vermelha{
        transform: translate(500px, 280px);
    }
    .produto-nutricional-img-cachaca-vermelha{
    width:248px; 
    height:861px;
    }

}
@media (max-width: 1620px){
    .content-group-container-cachaca-vermelha{
        transform: translate(-200px,-100px);
    
    }
    .title-container-cachaca-vermelha{
        transform: translate(20px, 190px);
        
    }
    .title-cachaca-vermelha{
        font-size: 5.5rem;
        margin-bottom:0;
        
    }
    .logo-img-container-cachaca-vermelha{
        transform: translate(160px, 120px);
    }
    .logo-img-cachaca-vermelha{
        width:173.61px; 
        height:128.4716px; 
    }
    .about-cachaca-vermelha{
        width: 360px;
        border-radius: 50px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom: 0;
        border-style: solid;
        bottom: 0;
        transform: translateX(-200px);
        padding-bottom: 50px;
    
    }
    .about-title-cointainer-cachaca-vermelha{
        margin-top:20px;
        padding: 5px;
    }

    .about-title-cachaca-vermelha{
        font-size: 1rem;
        margin-top:0;
        margin-bottom: 0;
    
    }
    .about-text-container-cachaca-vermelha{
        margin-top: 20px;
    }
    .about-text-cachaca-vermelha{
        font-size: 0.8rem;
        margin-top: 0;
    }
    .about-text2-cachaca-vermelha{
        font-size: 0.8rem;
        margin-top: 0;
    
    }
    .about-text3-cachaca-vermelha{
        font-size: 0.8rem;
        margin-top: 0;
        margin-bottom: 0;
    }
    .line-container-cachaca-vermelha{
        border-bottom:2px solid #003611;
        height:2px;
        width: 90%;
    
    }
    
    .produto-nutricional-img-container-cachaca-vermelha{
        transform: translate(500px, 140px);
    }
    .produto-nutricional-img-cachaca-vermelha{
        width:248px; 
        height:861px;
    }
   

}

@media (max-width: 1386px){
    .content-group-container-cachaca-vermelha{
        transform: translate(-166.66px,-83.33px);
    
    }
    .title-container-cachaca-vermelha{
        transform: translate(16.66px, 130px);
        
    }
    .title-cachaca-vermelha{
        font-size: 4.583rem;
        margin-bottom:0;
        
    }
    .logo-img-container-cachaca-vermelha{
        transform: translate(133.33px, 143px);
    }
    .logo-img-cachaca-vermelha{
        width:120.5625px; 
        height:89.658px; 
    }
    .about-cachaca-vermelha{
        width: 300px;
        border-radius: 50px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom: 0;
        border-style: solid;
        bottom: 0;
        transform: translateX(-166.66px);
        padding-bottom: 41.66px;
    
    }
    .about-title-cointainer-cachaca-vermelha{
        margin-top:16.66px;
        padding: 4.166px;
    }

    .about-title-cachaca-vermelha{
        font-size: 0.833rem;
        margin-top:0;
        margin-bottom: 0;
    
    }
    .about-text-container-cachaca-vermelha{
        margin-top: 16.66px;
    }
    .about-text-cachaca-vermelha{
        font-size: 0.666rem;
        margin-top: 0;
    }
    .about-text2-cachaca-vermelha{
        font-size: 0.666rem;
        margin-top: 0;
    
    }
    .about-text3-cachaca-vermelha{
        font-size: 0.666rem;
        margin-top: 0;
        margin-bottom: 0;
    }
    .line-container-cachaca-vermelha{
        border-bottom:2px solid #003611;
        height:2px;
        width: 90%;
    
    }
    
    .produto-nutricional-img-container-cachaca-vermelha{
        transform: translate(416.66px, 150px);
    }
    .produto-nutricional-img-cachaca-vermelha{
        width:172.216px; 
        height:597.916px;
    }

   

}
@media (max-width: 1300px){
    .content-group-container-cachaca-vermelha{
        transform: translate(-166.66px,-83.33px);
    
    }
    .title-container-cachaca-vermelha{
        transform: translate(50px, 120px);
        
    }
    .title-cachaca-vermelha{
        font-size: 3.819rem;
        margin-bottom:0;
        
    }
    .logo-img-container-cachaca-vermelha{
        transform: translate(160px, 143px);
    }
    .logo-img-cachaca-vermelha{
        width:120.5625px; 
        height:89.65px;  
    }
    .about-cachaca-vermelha{
        width: 300px;
        border-radius: 50px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom: 0;
        border-style: solid;
        bottom: 0;
        transform: translateX(-166.66px);
        padding-bottom: 41.66px;
    
    }
    .about-title-cointainer-cachaca-vermelha{
        margin-top:16.66px;
        padding: 4.166px;
    }

    .about-title-cachaca-vermelha{
        font-size: 0.833rem;
        margin-top:0;
        margin-bottom: 0;
    
    }
    .about-text-container-cachaca-vermelha{
        margin-top: 16.66px;
    }
    .about-text-cachaca-vermelha{
        font-size: 0.666rem;
        margin-top: 0;
    }
    .about-text2-cachaca-vermelha{
        font-size: 0.666rem;
        margin-top: 0;
    
    }
    .about-text3-cachaca-vermelha{
        font-size: 0.666rem;
        margin-top: 0;
        margin-bottom: 0;
    }
    .line-container-cachaca-vermelha{
        border-bottom:2px solid #003611;
        height:2px;
        width: 90%;
    
    }
    
    .produto-nutricional-img-container-cachaca-vermelha{
        transform: translate(400.66px, 140px);
    }
    .produto-nutricional-img-cachaca-vermelha{
        width:172.216px; 
        height:597.916px;
    }

}
@media (max-width: 980px){
    .content-group-container-cachaca-vermelha{
        transform: translate(-138.33px,-69.44px);
    
    }
    .title-container-cachaca-vermelha{
        transform: translate(13.88px, 136.66px);
        
    }
    .title-cachaca-vermelha{
        font-size: 3.819rem;
        margin-bottom:0;
        
    }
    .logo-img-container-cachaca-vermelha{
        transform: translate(111.11px, 105.83px);
    }
    .logo-img-cachaca-vermelha{
        width:120.5625px; 
        height:89.658px; 
    }
    .about-cachaca-vermelha{
        width: 250px;
        border-radius: 50px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom: 0;
        border-style: solid;
        bottom: 0;
        transform: translateX(-138.883px);
        padding-bottom: 34.716px;
    
    }
    .about-title-cointainer-cachaca-vermelha{
        margin-top:13.88px;
        padding: 3.47px;
    }

    .about-title-cachaca-vermelha{
        font-size: 0.6941rem;
        margin-top:0;
        margin-bottom: 0;
    
    }
    .about-text-container-cachaca-vermelha{
        margin-top: 13.88px;
    }
    .about-text-cachaca-vermelha{
        font-size: 0.55rem;
        margin-top: 0;
    }
    .about-text2-cachaca-vermelha{
        font-size: 0.55rem;
        margin-top: 0;
    
    }
    .about-text3-cachaca-vermelha{
        font-size: 0.55rem;
        margin-top: 0;
        margin-bottom: 0;
    }
    .line-container-cachaca-vermelha{
        border-bottom:2px solid #003611;
        height:2px;
        width: 90%;
    
    }
    
    .produto-nutricional-img-container-cachaca-vermelha{
        transform: translate(347.216px, 100px);
    }
    .produto-nutricional-img-cachaca-vermelha{
        width:172.216px; 
        height:597.91px;
    }
    
  

}

@media (max-width: 820px){
    .content-group-container-cachaca-vermelha{
        transform: translate(-115.275px,-57.866px);
    
    }
    .title-container-cachaca-vermelha{
        transform: translate(11.56px, 130px);
        
    }
    .title-cachaca-vermelha{
        font-size: 3.1825rem;
        margin-bottom:0;
        
    }
    .logo-img-container-cachaca-vermelha{
        transform: translate(92.59px, 150px);
    }
    .logo-img-cachaca-vermelha{
        width:100.46px; 
        height:74.715px; 
    }
    .about-cachaca-vermelha{
        width: 208.333px;
        border-radius: 50px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom: 0;
        border-style: solid;
        bottom: 0;
        transform: translateX(-115.736px);
        padding-bottom: 28.93px;
    
    }
    .about-title-cointainer-cachaca-vermelha{
        margin-top:11.56px;
        padding: 2.89px;
    }

    .about-title-cachaca-vermelha{
        font-size: 0.578rem;
        margin-top:0;
        margin-bottom: 0;
    
    }
    .about-text-container-cachaca-vermelha{
        margin-top: 11.566px;
    }
    .about-text-cachaca-vermelha{
        font-size: 0.458rem;
        margin-top: 0;
    }
    .about-text2-cachaca-vermelha{
        font-size: 0.458rem;
        margin-top: 0;
    
    }
    .about-text3-cachaca-vermelha{
        font-size: 0.458rem;
        margin-top: 0;
        margin-bottom: 0;
    }
    .line-container-cachaca-vermelha{
        border-bottom:2px solid #003611;
        height:2px;
        width: 90%;
    
    }
    
    .produto-nutricional-img-container-cachaca-vermelha{
        transform: translate(289.346px, 120px);
    }
    .produto-nutricional-img-cachaca-vermelha{
        width:119.594px; 
        height:415.215px;
    }
   
   
}