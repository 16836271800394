.container-contra-capa{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
    align-items: center;
    width: 100vw;
}
.img-contra-capa{
    width:483px; 
    height:357px; 
}
.line-container-contra-capa{
    margin-top: 50px;
    border-bottom:10px solid black;
    height: 10px;
    width: 30vw;
    display: flex;
    align-items: center;
    justify-content: center;

}
.text-bold-container-contra-capa{
    display: flex;
}
.text-bold-contra-capa{
    font-size: 2rem;
    font-family: "Square721-Bold";
}

.text-container-contra-capa{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.text-contra-capa{
    font-size: 1.5rem;
    font-family: "Square721";
    line-height: 2px;
}

@font-face {
    font-family: "Square721";
    src: url("../../../fonts/Square721/square721-cn-bt-roman.ttf");
  }

@font-face {
    font-family: "Square721-Bold";
    src: url("../../../fonts/Square721/Square-721-Bold-Condensed-BT_42056.ttf");
}

@media (max-width: 1620px){
    .img-contra-capa{
        width:402.5px; 
        height:297.5px; 
    }
    .line-container-contra-capa{
        margin-top: 41.66px;
        border-bottom:8.333px solid black;
        height: 8.333px;
        width: 30vw;
    
    }
    .text-bold-contra-capa{
        font-size: 1.666rem;
    }
    .text-contra-capa{
        font-size: 1.25rem;
        line-height: 1.666px;
    }

}

@media (max-width: 1386px){
    .img-contra-capa{
        width:335.416px; 
        height:247.916px; 
    }
    .line-container-contra-capa{
        margin-top: 34.716px;
        border-bottom:6.944px solid black;
        height: 6.944px;
        width: 30vw;
    
    }
    .text-bold-contra-capa{
        font-size: 1.3883rem;
    }
    .text-contra-capa{
        font-size: 1.041rem;
        line-height: 1.3883px;
    }


}

@media (max-width: 960px){
    .img-contra-capa{
        width:279.513px; 
        height:206.5966px; 
    }
    .line-container-contra-capa{
        margin-top: 28.93px;
        border-bottom:5.7866px solid black;
        height: 5.7866px;
        width: 30vw;
    
    }
    .text-bold-contra-capa{
        font-size: 1.1569rem;
    }
    .text-contra-capa{
        font-size: 0.8675rem;
        line-height: 1.1569px;
    }


}

@media (max-width: 820px){
    .img-contra-capa{
        width:232.9275px; 
        height:172.164px; 
    }
    .line-container-contra-capa{
        margin-top: 24.11px;
        border-bottom:4.822px solid black;
        height: 4.822px;
        width: 30vw;
    
    }
    .text-bold-contra-capa{
        font-size: 0.9641rem;
    }
    .text-contra-capa{
        font-size: 0.723rem;
        line-height: 0.9641px;
    }

}

