
.container-capa-mobile{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    align-items: center;
    width:100vw;
}


.logo-capa-container-mobile{
    display: flex;
    height: 80vh;
    width: 100vw;
    justify-content: center;
    align-items: center;
}
.logo-capa-mobile{
    width:300px; 
    height:297px;
}

.footer-capa-mobile{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    bottom: 0;
    width:100vw;
    color:white;
    background-color: #e30613;

}