.container-doce-de-leite-mobile{
    height: 100vh;
    width: 100vw;
    background-image: url("/src/assets/doce-de-leite/background-doce-de-leite.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size:cover;
    display: flex;
    justify-content: center;
}
.title-content-doce-de-leite-mobile{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.title-logo-doce-de-leite-mobile{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}
.title-doce-de-leite-mobile{
    font-family:"HeyAugust";
    color: #FFFFFF;
    text-align: right;
    margin-bottom: 0;
    font-size: 2.4rem;
}
.logo-doce-de-leite-mobile{
    width:120.55px; 
    height:89.21px; 
    transform: translateX(24.104px);
}
.subtitle-content-doce-de-leite-mobile{
    display: flex;
    width: fit-content;

}
.subtitle-doce-de-leite-mobile{
    font-family:"Belgates";
    color: #1D1D1B;
    text-align: center;
    margin-top: 0;
    font-size: 0.96rem;
}


.about-doce-de-leite-mobile{
    display: flex;
    width: 173.61px;
    height: fit-content;
    border-radius: 40px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: 0;
    border-color: #003611;
    border-style: solid;
    background-color: rgba(255,255,255,0.9);
    bottom: 0;
    transform: translateX(-20vw);
    position: absolute;
    flex-direction: column;
    align-items: center;
    padding-bottom: 24.11px;

}
.about-title-cointainer-doce-de-leite-mobile{
    display: flex;
    width: fit-content;
    height: fit-content;
    margin-top:9.641px;
        padding: 2.410px;
}
.about-title-doce-de-leite-mobile{
    font-size: 0.96rem;
    color: #1D1D1B;
    font-family: "FreeHand";
    margin-top:0;
    margin-bottom: 0;

}
.about-text-container-doce-de-leite-mobile{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 9.638px;
}
.about-text-doce-de-leite-mobile{
    font-size: 0.723rem;
    color: #1D1D1B;
    font-family: "FreeHand";
    text-align: center;
    margin-top: 0;
}
.about-text3-doce-de-leite-mobile{
    font-size: 0.3854rem;
    color: #1D1D1B;
    font-family: "HelveticaLTBold";
    margin-top: 0;
    margin-bottom: 0;
}
.line-container-doce-de-leite-mobile{
    border-bottom:2px solid #003611;
    height:2px;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;

}
.produto-doce-de-leite-mobile{
    position: absolute;
    bottom: 0;
    right: 0;
    width: 144.675px;
    height:253.175px;
}

@font-face {
    font-family: "HeyAugust";
    src: url("../../../fonts/Hey August/Hey August.ttf");
  }

@font-face {
    font-family: "Belgates";
    src: url("../../../fonts/Belgates/Belgates Script.ttf");
  }

@font-face {
    font-family: "FreeHand";
    src: url("../../../fonts/FreeHand/Freehand521BT.ttf");
  }