.container-vinho2{
    display:flex;
    height: 100vh;
    width: 100vw;
}
.left-top-vinho2{
    left:0;
    top:0;
    position: absolute;
    width:400px;
    height:400px; 
}
.right-top-vinho2{
    right:0;
    top:0;
    position: absolute;
    width:400px;
    height:400px; 
}
.right-bottom-vinho2{
    right:0;
    bottom:0;
    position: absolute;
    width:400px;
    height:400px; 
}
.produto-vinho2{
    position: absolute;
    transform: translate(850px, 50px);
    width:325px;
    height:607px;
}
.topbar-vinho2{
    width: 100vw;
    height: 30vh;
    top:0;
    background-color: #822330;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.content-group-vinho2{
    height: 100vh;
    justify-content: flex-start;
    display: flex;
    flex-direction: column;
    align-content: flex-start;
}

.content-vinho2{
    height:70vh;
    display:flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    margin-top: 10px;
}
.content-text-group-vinho2{
    display: flex;
    flex-direction: column;
}
.content-text-image-vinho2{
    display: flex;
    flex-direction: row;

}
.logo-vinho2{
    left:0;
    bottom:0;
    position: absolute;
    margin-left: 50px;
    margin-bottom: 70px;
    width:200px;
    height:148px;

}
.logo2-vinho2{
    width:125px;
    height:118px;
}
.topbar-text-container-vinho2{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.topbar-text-bold-vinho2{
    font-family: "D-Din-Condensed-Bold";
    font-size: 2rem;
    color: white;

}
.topbar-text-vinho2{
    font-family: "D-Din-Condensed";
    font-size: 2rem;
    color: white;
    line-height: 2px;
    margin:0;

}



.content-text1-vinho2{
    font-family: "Swiss-Cn-BT";
    font-size: 1.2rem;
    color:black;
    margin-bottom: 0;
}
.content-text1-english-vinho2{
    font-family: "Swiss-Cn-BT";
    font-size: 1.2rem;
    color:#878787;
    margin-top: 0;
    margin-bottom: 0;

}

.text-box-container-vinho2{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: fit-content;
    /* transform: translateX(-370px); */
    transform: translateX(-510px);
    margin-top: 25px;
}
.text-box-vinho2{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 275px;
    height: 150px;
    background-color: #822330;
    flex-direction: column;
}
.text-box-text-vinho2{
    font-size: 2rem;
    color:white;
    font-family: "Swiss-Cn-BT-Italic";
    margin:0;
    margin-left: 25px;

}
.text-box-text2-vinho2{
    font-size: 1.2rem;
    color:white;
    font-family: "Swiss-Cn-BT-Italic";
    margin:0;
    margin-left: 25px;
}
.text-box-text2-junto-vinho2{
    font-size: 1.2rem;
    color:white;
    font-family: "Swiss-Cn-BT-Italic";
    margin:0;
}

.text-box2-vinho2{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 275px;
    height: 100px;
    background-color: transparent;
    flex-direction: column;
    margin-top: 25px;

}

.text-box-text-english-vinho2{
    font-size: 2rem;
    color:#878787;
    font-family: "Swiss-Cn-BT-Italic";
    margin:0;
    margin-left: 25px;

}
.text-box-text2-english-vinho2{
    font-size: 1.2rem;
    color:#878787;
    font-family: "Swiss-Cn-BT-Italic";
    margin:0;
    margin-left: 25px;
}
.text-box-text2-english-junto-vinho2{
    font-size: 1.2rem;
    color:#878787;
    font-family: "Swiss-Cn-BT-Italic";
    margin:0;
}

@font-face {
    font-family: "D-Din-Condensed";
    src: url("../../../fonts/DDinCondensed/D-DINCondensed.otf");
  }
  @font-face {
    font-family: "D-Din-Condensed-Bold";
    src: url("../../../fonts/DDinCondensed/D-DINCondensed-Bold.otf");
  }
  @font-face {
    font-family: "Swiss-Cn-BT";
    src: url("../../../fonts/Swiss721/Swiss721CondensedBT.ttf");
  }

  @font-face {
    font-family: "Swiss-Cn-BT-Italic";
    src: url("../../../fonts/Swiss721/Swiss721CondensedItalicBT.ttf");
  }

  @media (max-width: 1620px){
    .left-top-vinho2{

        width:333.333px;
        height:333.333px;
    }
    .right-top-vinho2{
    
        width:333.333px;
        height:333.333px; 
    }
    .right-bottom-vinho2{
    
        width:333.333px;
        height:333.333px;
    }
    .produto-vinho2{

        transform: translate(708.333px, 41.666px);
        width:270.833px;
        height:505.833px;
    }
    .content-vinho2{
        margin-top: 8.333px;
    }
    .logo-vinho2{
        margin-left: 41.666px;
        margin-bottom: 58.333px;
        width:166.666px;
        height:123.333px;
    
    }
    .logo2-vinho2{
        width:104.166px;
        height:98.333px;
    }
    .topbar-text-bold-vinho2{
        font-size: 1.666rem;
    
    }
    .topbar-text-vinho2{
        font-size: 1.666rem;
        line-height: 1.666rem;
        margin:0;
    
    }
    .content-text1-vinho2{
        font-size: 1rem;
        margin-bottom: 0;
    }
    .content-text1-english-vinho2{
        font-size: 1rem;
        margin-top: 0;
        margin-bottom: 0;
    
    }
    .text-box-container-vinho2{
        transform: translateX(-424.424px);
        margin-top: 20.833px;
    }
    .text-box-vinho2{
        width: 229.166px;
        height: 125px;
    }
    .text-box-text-vinho2{
        font-size: 1.666rem;
        margin-left: 20.833px;
    
    }
    .text-box-text2-vinho2{
        font-size: 1rem;
        margin-left: 20.833px;
    }
    .text-box-text2-junto-vinho2{
        font-size: 1rem;
    }
    .text-box2-vinho2{
        width: 229.166px;
        height: 83.333px;
        margin-top: 20.833px;
    
    }
    .text-box-text-english-vinho2{
        font-size: 1.66rem;
        margin-left: 20.833px;
    
    }
    .text-box-text2-english-vinho2{
        font-size: 1rem;
        margin-left: 20.833px;
    }
    .text-box-text2-english-junto-vinho2{
        font-size: 1rem;
    }
    
    
  
}

@media (max-width: 1386px){
    .left-top-vinho2{

        width:277.777px;
        height:277.777px;
    }
    .right-top-vinho2{
    
        width:277.777px;
        height:277.777px; 
    }
    .right-bottom-vinho2{
    
        width:277.777px;
        height:277.777px;
    }
    .produto-vinho2{

        transform: translate(590.2775px, 34.7216px);
        width:225.6941px;
        height:421.5275px;
    }
    .content-vinho2{
        margin-top: 6.9441px;
    }
    .logo-vinho2{
        margin-left: 34.7216px;
        margin-bottom: 48.611px;
        width:138.888px;
        height:102.7775px;
    
    }
    .logo2-vinho2{
        width:86.805px;
        height:81.944px;
    }
    .topbar-text-bold-vinho2{
        font-size: 1.3883rem;
    
    }
    .topbar-text-vinho2{
        font-size: 1.3883rem;
        line-height: 1.3883rem;
        margin:0;
    
    }
    .content-text1-vinho2{
        font-size: 0.833rem;
        margin-bottom: 0;
    }
    .content-text1-english-vinho2{
        font-size: 0.833rem;
        margin-top: 0;
        margin-bottom: 0;
    
    }
    .text-box-container-vinho2{
        transform: translateX(-353.768px);
        margin-top: 17.361px;
    }
    .text-box-vinho2{
        width: 190.9716px;
        height: 104.166px;
    }
    .text-box-text-vinho2{
        font-size: 1.3883rem;
        margin-left: 17.361px;
    
    }
    .text-box-text2-vinho2{
        font-size: 0.833rem;
        margin-left: 17.361px;
    }
    .text-box-text2-junto-vinho2{
        font-size: 0.833rem;
    }
    .text-box2-vinho2{
        width: 190.971px;
        height: 69.444px;
        margin-top: 17.361px;
    
    }
    .text-box-text-english-vinho2{
        font-size: 1.3833rem;
        margin-left: 17.361px;
    
    }
    .text-box-text2-english-vinho2{
        font-size: 0.833rem;
        margin-left: 17.361px;
    }
    .text-box-text2-english-junto-vinho2{
        font-size: 0.833rem;
    }

}

@media (max-width: 960px){

    .left-top-vinho2{
        width:231.481px;
        height:231.481px;
    }
    .right-top-vinho2{
    
        width:231.481px;
        height:231.481px;
    }
    .right-bottom-vinho2{
    
        width:231.481px;
        height:231.481px;
    }
    .produto-vinho2{

        transform: translate(491.8979px, 28.934px);
        width:188.078px;
        height:351.273px;
    }
    .content-vinho2{
        margin-top: 5.7867px;
    }
    .logo-vinho2{
        margin-left: 28.9346px;
        margin-bottom: 25.51px;
        width:115.74px;
        height:85.6479px;
    
    }
    .logo2-vinho2{
        width:72.3375px;
        height:68.286px;
    }
    .topbar-text-bold-vinho2{
        font-size: 1.1569rem;
    
    }
    .topbar-text-vinho2{
        font-size: 1.1569rem;
        line-height: 1.1569rem;
        margin:0;
    
    }
    .content-text1-vinho2{
        font-size: 0.69416rem;
        margin-bottom: 0;
    }
    .content-text1-english-vinho2{
        font-size: 0.694166rem;
        margin-top: 0;
        margin-bottom: 0;
    
    }
    .text-box-container-vinho2{
        transform: translateX(-295.057px);
        margin-top: 14.4675px;
    }
    .text-box-vinho2{
        width: 159.143px;
        height: 86.805px;
    }
    .text-box-text-vinho2{
        font-size: 1.1569rem;
        margin-left: 14.4675px;
    
    }
    .text-box-text2-vinho2{
        font-size: 0.6941rem;
        margin-left: 14.4675px;
    }
    .text-box-text2-junto-vinho2{
        font-size: 0.6941rem;
    }
    .text-box2-vinho2{
        width: 159.1425px;
        height: 57.87px;
        margin-top: 14.4675px;
    
    }
    .text-box-text-english-vinho2{
        font-size: 1.15275rem;
        margin-left: 14.4675px;
    
    }
    .text-box-text2-english-vinho2{
        font-size: 0.6941rem;
        margin-left: 14.4675px;
    }
    .text-box-text2-english-junto-vinho2{
        font-size: 0.6941rem;
    }

}
@media (max-width: 820px){
    .left-top-vinho2{
        width:192.9px;
        height:192.9px;
    }
    .right-top-vinho2{
    
        width:192.9px;
        height:192.9px;
    }
    .right-bottom-vinho2{
    
        width:192.9px;
        height:192.9px;
    }
    .produto-vinho2{

        transform: translate(409.91px, 24.111px);
        width:156.73px;
        height:292.7275px;
    }
    .content-vinho2{
        margin-top: 4.822px;
    }
    .logo-vinho2{
        margin-left: 24.11px;
        margin-bottom: 21.26px;
        width:96.45px;
        height:71.37px;
    
    }
    .logo2-vinho2{
        width:60.28px;
        height:56.905px;
    }
    .topbar-text-bold-vinho2{
        font-size: 0.9641rem;
    
    }
    .topbar-text-vinho2{
        font-size: 0.9641rem;
        line-height: 0.9641rem;
        margin:0;
    
    }
    .content-text1-vinho2{
        font-size: 0.578rem;
        margin-bottom: 0;
    }
    .content-text1-english-vinho2{
        font-size: 0.578rem;
        margin-top: 0;
        margin-bottom: 0;
    
    }
    .text-box-container-vinho2{
        transform: translateX(-245.88px);
        margin-top: 12.05px;
    }
    .text-box-vinho2{
        width: 132.62px;
        height: 72.3375px;
    }
    .text-box-text-vinho2{
        font-size: 0.9641rem;
        margin-left: 12.056px;
    
    }
    .text-box-text2-vinho2{
        font-size: 0.578rem;
        margin-left: 12.056px;
    }
    .text-box-text2-junto-vinho2{
        font-size: 0.578rem;
    }
    .text-box2-vinho2{
        width: 132.618px;
        height: 48.225px;
        margin-top: 12.056px;
    
    }
    .text-box-text-english-vinho2{
        font-size: 0.96rem;
        margin-left: 12.056px;
    
    }
    .text-box-text2-english-vinho2{
        font-size: 0.578rem;
        margin-left: 12.056px;
    }
    .text-box-text2-english-junto-vinho2{
        font-size: 0.578rem;
    }

}