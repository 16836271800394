

/* 2560×1440, 1920×1080 */
.container-home{
    display: flex;
    width: 100vw;

}
.arrow-left-carousel-home{
    display: flex;
    position: absolute;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    border-style: solid;
    border-color: transparent;
    background-color: white;
    z-index: 100;
    transform: translateX(10px);
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    display:flex;
    align-items: center;
    justify-content: center;

}
.arrow-right-carousel-home{
    display: flex;
    position: absolute;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    border-style: solid;
    border-color: transparent;
    background-color: white;
    transform: translateX(-60px);
    z-index: 100;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    display:flex;
    align-items: center;
    justify-content: center;
}
.arrow-left-disabled-carousel-home{
    display: flex;
    position: absolute;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    border-style: solid;
    border-color: transparent;
    transform: translateX(10px);
    background-color: #e0e0e2;
    z-index: 100;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    display:flex;
    align-items: center;
    justify-content: center;
    
}
.arrow-right-disabled-carousel-home{
    display: flex;
    position: absolute;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    border-style: solid;
    border-color: transparent;
    background-color: #e0e0e2;
    transform: translateX(-60px);
    z-index: 100;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    display:flex;
    align-items: center;
    justify-content: center;
}

