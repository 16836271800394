.container-vinho2-mobile{
    display:flex;
    height: 100vh;
    width: 100vw;
}
.left-top-vinho2-mobile{
    left:0;
    top:0;
    position: absolute;
    width:160px;
    height:160px;
}
.right-top-vinho2-mobile{
    right:0;
    top:0;
    position: absolute;
    width:160px;
    height:160px; 
}
.right-bottom-vinho2-mobile{
    right:0;
    bottom:0;
    position: absolute;
    width:160px;
    height:160px; 
}
.produto-vinho2-mobile{
    position: absolute;
    right:0;
    bottom:0;
    width:130px;
    height:242.8px;
}
.topbar-vinho2-mobile{
    width: 100vw;
    height: 30vh;
    top:0;
    background-color: #822330;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.content-group-vinho2-mobile{
    height: 100vh;
    justify-content: flex-start;
    display: flex;
    flex-direction: column;
    align-content: flex-start;
}

.content-vinho2-mobile{
    height:70vh;
    display:flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    margin-top: 4px;
}
.content-text-group-vinho2-mobile{
    display: flex;
    flex-direction: column;
}
.content-text-image-vinho2-mobile{
    display: flex;
    flex-direction: column;

}
.logo-vinho2-mobile{
    left:0;
    bottom:0;
    position: absolute;
    margin-left: 20px;
    margin-bottom: 28px;
    width:80px;
    height:59.2px;

}
.logo2-vinho2-mobile{
    width:50px;
    height:47.2px;
}
.topbar-text-container-vinho2-mobile{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.topbar-text-bold-vinho2-mobile{
    font-family: "D-Din-Condensed-Bold";
    font-size: 0.8rem;
    color: white;

}
.topbar-text-vinho2-mobile{
    font-family: "D-Din-Condensed";
    font-size: 0.8rem;
    color: white;
    line-height: 2px;
    margin:0;

}



.content-text1-vinho2-mobile{
    font-family: "Swiss-Cn-BT";
    font-size: 0.48rem;
    color:black;
    margin-bottom: 0;
}
.content-text1-english-vinho2-mobile{
    font-family: "Swiss-Cn-BT";
    font-size: 0.48rem;
    color:#878787;
    margin-top: 0;
    margin-bottom: 0;

}

.text-box-container-vinho2-mobile{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: fit-content;
    left:0;
    margin-top: 10px;
}
.text-box-vinho2-mobile{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 110px;
    height: 60px;
    background-color: #822330;
    flex-direction: column;
}
.text-box-text-vinho2-mobile{
    font-size: 0.8rem;
    color:white;
    font-family: "Swiss-Cn-BT-Italic";
    margin:0;
    margin-left: 10px;

}
.text-box-text2-vinho2-mobile{
    font-size: 0.48rem;
    color:white;
    font-family: "Swiss-Cn-BT-Italic";
    margin:0;
    margin-left: 10px;
}
.text-box-text2-junto-vinho2-mobile{
    font-size: 0.48rem;
    color:white;
    font-family: "Swiss-Cn-BT-Italic";
    margin:0;
}

.text-box2-vinho2-mobile{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 110px;
    height: 40px;
    background-color: transparent;
    flex-direction: column;
    margin-top: 10px;

}

.text-box-text-english-vinho2-mobile{
    font-size: 0.8rem;
    color:#878787;
    font-family: "Swiss-Cn-BT-Italic";
    margin:0;
    margin-left: 10px;

}
.text-box-text2-english-vinho2-mobile{
    font-size: 0.48rem;
    color:#878787;
    font-family: "Swiss-Cn-BT-Italic";
    margin:0;
    margin-left: 10px;
}
.text-box-text2-english-junto-vinho2-mobile{
    font-size: 0.48rem;
    color:#878787;
    font-family: "Swiss-Cn-BT-Italic";
    margin:0;
}

@font-face {
    font-family: "D-Din-Condensed";
    src: url("../../../fonts/DDinCondensed/D-DINCondensed.otf");
  }
  @font-face {
    font-family: "D-Din-Condensed-Bold";
    src: url("../../../fonts/DDinCondensed/D-DINCondensed-Bold.otf");
  }
  @font-face {
    font-family: "Swiss-Cn-BT";
    src: url("../../../fonts/Swiss721/Swiss721CondensedBT.ttf");
  }

  @font-face {
    font-family: "Swiss-Cn-BT-Italic";
    src: url("../../../fonts/Swiss721/Swiss721CondensedItalicBT.ttf");
  }

  