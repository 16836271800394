.container-bananinha-mobile{
    height: 100vh;
    width: 100vw;
    background-image: url("/src/assets/bananinha/background-bananinha.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size:cover;
    display: flex;
    justify-content: center;
    
}
.content-group-wrapper-bananinha-mobile{
    display: flex;
    flex-direction: column;
    height: fit-content;
    width: fit-content;
    
}
.content-group-container-bananinha-mobile{
    display: flex;
    flex-direction: row;
    height: fit-content;
    width: fit-content;


}
.content-group2-container-bananinha-mobile{
    display: flex;
    flex-direction: row;
    height: fit-content;
    width: fit-content;
    
}

.content-group3-container-bananinha-mobile{
    display: flex;
    flex-direction: column;
    width: fit-content;
    justify-content: center;
    align-items: center;
}
.title-group-container-bananinha-mobile{
    display: flex;
    flex-direction: column;
    transform: translateX(50px);

}
.title-container-bananinha-mobile{
    align-items: center;
    justify-content: center;
}
.title-bananinha-mobile{
    font-size: 2.5rem;
    font-family: "HeyAugust";
    color: #003611;
    margin-bottom:0;
}
.title2-bananinha-mobile{
    font-size: 2rem;
    font-family: "HeyAugust";
    color: #003611;
    margin-top:0;
    margin-bottom:0;
    margin-left: 20px;
}
.title-container2-bananinha-mobile{
    width:200px;
    align-items: center;
    justify-content: center;
}
.title3-bananinha-mobile{
    font-size: 1.5rem;
    font-family: "HeyAugust";
    color: white;
    margin-top:0;
    margin-left: 30px;
}
.logo-img-container-bananinha-mobile{
    display: flex;
    align-items: center;
    transform: translate(20px, 20px);
}

.about-bananinha-mobile{
    display: flex;
    width: 230px;
    height: 220px;
    border-radius: 50px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: 0;
    border-color: #003611;
    border-style: solid;
    background-color: rgba(255,255,255,0.9);
    bottom: 0;
    position: absolute;
    flex-direction: column;
    align-items: center;
    transform: translateX(50px);

    

}
.about-title-bananinha-mobile{
    font-size: 1.5rem;
    color: #003611;
    font-family: "Belgates";
    margin-top:0;
    margin-bottom: 0;

}
.about-text-container-bananinha-mobile{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.about-text-bananinha-mobile{
    font-size: 1.2rem;
    line-height: 25px;
    color: #3e3f3a;
    font-family: "Belgates";
    margin-top: 0;
    margin-bottom: 0;


}
.produto-nutricional-img-container-bananinha-mobile{
    display: flex;
    position: absolute;
    transform: translate(170px, 15vh);
}
.produto-nutricional-img-bananinha-mobile{
    width:164px; 
    height:200px; 
}
@font-face {
    font-family: "HeyAugust";
    src: url("../../../fonts/Hey August/Hey August.ttf");
  }
  @font-face {
    font-family: "Belgates";
    src: url("../../../fonts/Belgates/Belgates Script.ttf");
  }
@media (max-height: 568px){
    .produto-nutricional-img-bananinha-mobile{
        width:136.66px; 
        height:166.66px; 
    }

}
  @media (min-height: 650px){
    .produto-nutricional-img-bananinha-mobile{
        width:196.8px; 
        height:240px; 
    }

}